import React from 'react'
import baby from '../images/baby.svg'
import Layout from '../components/layout'
import SEO from '../components/seo'
import pillow from '../assets/baby-pack/pillow.svg'
import top from '../assets/top.svg'
import bottom from '../assets/bottom.svg'

function IndexPage() {
  // const query = useStaticQuery(graphql`
  //   {
  //     prismicHome {
  //       data {
  //         home_header_link {
  //           url
  //         }
  //         home_header_image {
  //           url
  //         }
  //         home_title {
  //           text
  //         }
  //       }
  //     }
  //   }
  // `)
  // const data = useStaticQuery(graphql`
  //   query placeholderImage {
  //     file(relativePath: { regex: "/01/" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)
  // console.log(data.file.childImageSharp.fluid)

  //! here is the interesting part for Instagram
  // const data = useStaticQuery(graphql`
  //   query {
  //     allInstaNode {
  //       edges {
  //         node {
  //           id
  //           likes
  //           mediaType
  //           preview
  //           original
  //           timestamp
  //           caption
  //           localFile {
  //             childImageSharp {
  //               fixed(width: 150, height: 150) {
  //                 ...GatsbyImageSharpFixed
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)
  // console.log(data)

  return (
    <Layout>
      <SEO title="Home" />
      <div className="items-center max-w-6xl m-4 md:flex md:flex-row md:mx-auto md:px-4">
        <div className="flex flex-col w-full md:w-1/3 lg:mr-8 md:h-80 md:-mt-4 lg:-mt-20">
          <h1 className="text-4xl font-bold text-gray-700 font-header md:text-lg md:w-full lg:text-3xl">
            Votre enfant rencontre des difficultés de sommeil ?
          </h1>
          <p className="mt-2 text-xl tracking-wide text-gray-800 font-muli md:text-lg lg:text-lg qhd:text-xl">
            Laissez moi vous aider à trouver une solution !
          </p>
        </div>
        <div className="mt-6 md:w-2/3">
          <img src={baby} alt="Baby homepage logo" />
        </div>
      </div>
      {/* <Img fluid={data.file.childImageSharp.fluid} /> */}
      <div id="main-details" className="relative md:mt-24">
        <img src={top} className="relative hidden w-full lg:block" style={{ top: '2px' }} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="relative z-10 -mb-8 md:-mb-12 lg:hidden"
          viewBox="0 0 1440 320"
        >
          <path
            fill="hsl(291, 57%, 74%)"
            fillOpacity="1"
            d="M0,288L60,256C120,224,240,160,360,160C480,160,600,224,720,208C840,192,960,96,1080,80C1200,64,1320,128,1380,160L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            data-darkreader-inline-fill=""
          ></path>
          <path
            fill="hsl(291, 57%, 84%)"
            fillOpacity="1"
            d="M0,192L60,165.3C120,139,240,85,360,101.3C480,117,600,203,720,229.3C840,256,960,224,1080,192C1200,160,1320,128,1380,112L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            data-darkreader-inline-fill=""
          ></path>
        </svg>
        <div id="more-details" className="relative z-30 p-4 bg-lilas">
          <h2 className="text-2xl text-center font-muli tex t-gray-700 font-extrab old">
            Je peux vous aider si:
          </h2>
          <div
            id="flex-part"
            className="flex flex-col max-w-3xl mx-auto mt-8 md:justify-around md:flex-row md:content-around lg:max-w-6xl"
          >
            <section id="left-part" className="flex flex-col w-full md:justify-start md:w-1/2">
              <p className="text-xl text-gray-700 font-muli">
                <img src={pillow} alt="image d'oreiller" className="inline h-6 mr-1" />
                <span>Les siestes sont difficiles et ne durent pas longtemps</span>
              </p>
              <p className="text-xl text-gray-700 font-muli lg:mt-2">
                <img src={pillow} alt="image d'oreiller" className="inline h-6 mr-1" />
                <span>Votre enfant a du mal à s’endormir ou s’endort tard le soir</span>
              </p>
            </section>

            <section id="right-part" className="flex flex-col w-full md:justify-start md:w-1/2 md:pl-4">
              <p className="mt-2 text-xl text-gray-700 font-muli lg:mt-0">
                <img src={pillow} alt="image d'oreiller" className="inline h-6 mr-1" />
                <span>Votre enfant se réveille fréquemment pendant la nuit</span>
              </p>
              <p className="text-xl text-gray-700 font-muli lg:mt-2">
                <img src={pillow} alt="image d'oreiller" className="inline h-6 mr-1" />
                <span>Votre enfant se réveille très tôt le matin</span>
              </p>
            </section>
          </div>
          <section className="mt-2 text-2xl font-extrabold text-center text-gray-700 font-muli lg:text-2xl lg:mt-8">
            <p>Emmenons ensemble votre Bébé au pays du sommeil</p>
          </section>
          {/* <p className="text-xl text-gray-700 font-muli">
          Le sommeil est chaotique et n’apporte pas un repos suffisant ?
        </p> */}
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: 'scale(-1, 1)' }}
          className="z-10 -mt-8 md:-mb-16 lg:hidden"
          viewBox="0 0 1440 320"
        >
          <path
            fill="hsl(291, 57%, 74%)"
            fillOpacity="1"
            d="M0,288L60,261.3C120,235,240,181,360,154.7C480,128,600,128,720,160C840,192,960,256,1080,266.7C1200,277,1320,235,1380,213.3L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            data-darkreader-inline-fill=""
          ></path>
          <path
            fill="hsl(291, 57%, 84%)"
            fillOpacity="1"
            d="M0,192L60,165.3C120,139,240,85,360,101.3C480,117,600,203,720,229.3C840,256,960,224,1080,192C1200,160,1320,128,1380,112L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            data-darkreader-inline-fill=""
          ></path>
        </svg>
        <img src={bottom} className="relative hidden w-full lg:block" style={{ bottom: '1px' }} />
      </div>
    </Layout>
  )
}

export default IndexPage
